import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

// Redux
import { connect } from "react-redux"
import { Redirect, withRouter } from "react-router-dom"
import { isMobile } from "react-device-detect"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
//custom
import {
  CardBody,
  Card,
  Alert,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap"
import classnames from "classnames"
import SweetAlert from "react-bootstrap-sweetalert"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

const Alldata = JSON.parse(localStorage.getItem("dataJ"))

const Login = props => {
  const [ms, setMS] = useState(false)

  const [ActiveTab, setActiveTab] = useState("Team")
  const [activeTabContent, setactiveTabContent] = useState("2")
  const [alert, setAlert] = useState(true)
  const toggle1 = tab => {
    if (ActiveTab !== tab) {
      setActiveTab(tab)
    }
  }
  const [modal, toggleMS] = useState(false)
  const togms = () => {
    toggleMS(!modal)
  }
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, props.history)
  }
  const hideAlert = () => {
    setAlert(false)
  }
  const loader = document.querySelector(".loader")

  useEffect(() => {
    setTimeout(() => {
      loader.classList.add("loader--hide")
    }, 1000)
  }, [])

  return Alldata.loginData.holdingSite ? (
    <React.Fragment>
      <MetaTags>
        <title>MDRT Master Class 
</title>
      </MetaTags>
      {isMobile?
      <img
        src={
          "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/21kevents%2FMobile%20Holding%20Slide.jpg?alt=media"
        }
        style={{ position: "absolute",height:"100%", width: "100%" }}
      ></img>
      :
      <img
        src={
          "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
          encodeURIComponent(Alldata.loginData.holdingImg) +
          "?alt=media"
        }
        style={{ position: "absolute", width: "100%" }}
      ></img>}
      <Modal
        size="xl"
        isOpen={ms}
        toggle={() => {
          setMS(!ms)
        }}
        centered={true}
        style={{ width: "auto" }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Photo Mosaic</h5>
          <button
            type="button"
            onClick={() => {
              setMS(!ms)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <iframe
            allow="camera; microphone"
            title="test"
            className="embed-responsive-item mosaic"
            src={encodeURI("https://demo-mosaic.herokuapp.com/")}
          />
        </div>
      </Modal>
      <Container style={{ padding: 0, position: "relative" }} fluid>
      {isMobile?
      <img
        src={
          "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/21kevents%2FMobile%20Holding%20Slide.jpg?alt=media"
        }
        style={{ position: "absolute",height:"100%", width: "100%" }}
      ></img>
      :
      <img
        src={
          "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
          encodeURIComponent(Alldata.loginData.holdingImg) +
          "?alt=media"
        }
        style={{ position: "absolute", width: "100%" }}
      ></img>}
        
      </Container>
    </React.Fragment>
  ) : (
    <Redirect to={{ pathname: "/login" }} />
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
